<template>
	<v-card
		class="ticket-tile"
		:class="{ archived: isArchived }"
		v-on="$listeners"
		:ripple="false"
		:color="!ticket.visited ? 'green' : ''"
	>
		<v-card-text class="ticket-body">
			<div class="station-name" :class="{ 'text-white': !ticket.visited }">
				{{ ticket.substation ? ticket.substation.name : "Empty" }}
			</div>

			<div class="date">
				<v-icon class="icon" :class="{ 'text-white': !ticket.visited }">event</v-icon>
				<div class="date-value" :class="{ 'text-white': !ticket.visited }">
					{{ ticketDate }}
				</div>
			</div>

			<div class="time">
				<v-icon class="icon" :class="{ 'text-white': !ticket.visited }">schedule</v-icon>
				<div class="time-value" :class="{ 'text-white': !ticket.visited }">
					{{ ticketTime }}
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "TicketCardComponent",
	props: {
		ticket: {
			type: Object,
			required: true,
			default: null
		},
		isArchived: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	computed: {
		ticketDate() {
			return this.ticket.creationDate.toLocaleDateString("ru-RU");
		},
		ticketTime() {
			return this.ticket.creationDate.toLocaleTimeString("ru-RU").substr(0, 5);
		}
	}
};
</script>

<style scoped lang="scss">
.ticket-tile:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	transition: box-shadow 0.2s ease-in-out;
}

.ticket-tile {
	cursor: pointer;

	.text-white {
		color: white;
	}

	&.archived {
		opacity: 0.6;
	}

	.ticket-body {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.station-name {
			font-size: 20px;
			font-weight: 500;
			width: 45%;
		}

		.date,
		.time {
			display: flex;
			align-items: center;

			.icon {
				margin-right: 10px;
			}
		}
	}
}
</style>
