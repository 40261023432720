<template>
	<div class="ticket-list wrapper">
		<v-row>
			<!--Active-->
			<v-col>
				<div style="width: 85%">
					<div class="column-title">
						<v-icon class="icon">assignment</v-icon>
						<span class="column-label">Активные:</span>
					</div>

					<v-expansion-panels class="mt-5">
						<v-expansion-panel>
							<v-expansion-panel-header>
								<v-icon style="flex: 0">filter_alt</v-icon>
								Фильтры
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-text-field
									v-model="activeTicketsSearchQuery"
									label="Имя подстанции"
									clearable
								/>

								<v-menu
									ref="menu"
									v-model="menu"
									:close-on-content-click="false"
									:return-value.sync="activeTicketsSearchDate"
									transition="scale-transition"
									offset-y
									max-width="290px"
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="activeTicketsSearchDate"
											label="Дата"
											readonly
											v-bind="attrs"
											v-on="on"
											clearable
										/>
									</template>

									<v-date-picker
										v-model="activeTicketsSearchDate"
										no-title
										scrollable
									>
										<v-spacer />
										<v-btn
											text
											color="primary"
											@click="$refs.menu.save(activeTicketsSearchDate)"
										>
											OK
										</v-btn>
									</v-date-picker>
								</v-menu>

								<v-checkbox label="Новые" v-model="activeTicketsSearchForNew" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>

					<!--Ticket cards-->
					<div class="tickets" ref="activeTicketsContainer">
						<TicketCardComponent
							class="ticket"
							v-for="ticket in activeTickets"
							:key="ticket.id"
							:ticket="ticket"
							:data-id="ticket.id"
							@click="$router.push(`/ticket/${ticket.id}`)"
						/>
					</div>
				</div>
			</v-col>

			<!--Archived-->
			<v-col>
				<div style="width: 85%; margin-left: auto">
					<div class="column-title">
						<v-icon class="icon">archive</v-icon>
						<span class="column-label">Архивные:</span>
					</div>

					<!--Ticket cards-->
					<div class="tickets" ref="archivedTicketsContainer">
						<TicketCardComponent
							:is-archived="true"
							class="ticket"
							v-for="ticket in archivedTickets"
							:key="ticket.id"
							:ticket="ticket"
							@click="$router.push(`/ticket/${ticket.id}`)"
						/>
					</div>
				</div>
			</v-col>
		</v-row>

		<ArchiveTicketModalComponent
			ref="archiveModal"
			:are-extra-data-showing="false"
			:ticket-id="ticketIdToArchive"
		/>
	</div>
</template>

<script>
import TicketCardComponent from "@/components/TicketCardComponent";
import Sortable from "sortablejs";
import { mapActions, mapState } from "vuex";
import TicketService from "@/services/TicketService";
import ArchiveTicketModalComponent from "@/components/ArchiveTicketModalComponent";

export default {
	name: "TicketList",
	components: {
		ArchiveTicketModalComponent,
		TicketCardComponent
	},
	async mounted() {
		await this.loadTickets();

		new Sortable(this.$refs.activeTicketsContainer, {
			group: {
				name: "shared",
				put: false
			},
			animation: 150
		});

		new Sortable(this.$refs.archivedTicketsContainer, {
			group: "shared",
			onAdd: e => {
				const item = e.item;
				const id = parseInt(item.dataset.id);
				item.classList.add("archived");

				this.ticketIdToArchive = id;

				this.$refs.archiveModal.show();
			},
			animation: 150
		});
	},
	data: () => ({
		activeTicketsSearchQuery: "",
		activeTicketsSearchDate: null,
		activeTicketsSearchForNew: false,
		ticketIdToArchive: 0,
		menu: false
	}),
	computed: {
		...mapState(["tickets"]),
		activeTickets() {
			const filterByName = t =>
				!t.substation ||
				t.substation.name
					.toLowerCase()
					.includes(this.activeTicketsSearchQuery.toLowerCase());

			const filterByDate = t =>
				!this.activeTicketsSearchDate ||
				t.creationDate.setHours(0, 0, 0, 0) ===
					new Date(this.activeTicketsSearchDate).setHours(0, 0, 0, 0);

			const filterByNew = t => !this.activeTicketsSearchForNew || !t.visited;

			return this.tickets.filter(
				t => !t.isArchive && filterByName(t) && filterByDate(t) && filterByNew(t)
			);
		},
		archivedTickets() {
			return this.tickets.filter(t => t.isArchive);
		}
	},
	methods: {
		...mapActions(["loadTickets"])
	}
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

.ticket-list {
	margin-top: 35px;

	.column-title {
		display: flex;
		font-size: 20px;

		.column-label {
			border-bottom: 1px solid $primary;
		}

		.icon {
			margin-right: 5px;
			color: $primary;
		}
	}

	.tickets {
		margin-top: 15px;
		height: 100vh;

		.ticket {
			user-select: none;
			margin-bottom: 15px;
		}
	}
}
</style>
